import React, { useTransition, useState } from "react";
import "../styles/About.css";
import saroj from "../assets/About.jpg";
import Info from "./Info";

const TAB_DATA = [
  {
    title: "Skills",
    id: "Skills",
    content: (
      <ul>
        <li>JavaScript</li>
        <li>Java</li>
        <li>Python</li>
        <li>ReactJS</li>
        <li>NodeJS</li>
        <li>Express</li>
        <li>MongoDB</li>
        <li>SQL</li>
      </ul>
    ),
  },
  {
    title: "Education",
    id: "Education",
    content: (
      <ul>
        <li>
          BSc (Hons) Computing (2020 - 2023 - Itahari International College)
        </li>
        <li>+2 Science (2017 - 2020 - Vishwa Adarsha College)</li>
      </ul>
    ),
  },
  {
    title: "Experience",
    id: "Experience",
    content: (
      <ul>
        <li>Frontend Developer (3 Mos - GrihaSewa PVT.LTD)</li>
      </ul>
    ),
  },
];

const About = () => {
  const [tab, setTab] = useState("Skills");
  const [isPending, startTransition] = useTransition();

  const handleTabChange = (id) => {
    startTransition(() => {
      setTab(id);
    });
  };

  return (
    <div className="about-container" id="about">
      <div className="image">
        <img src={saroj} alt="saroj-profile"></img>
      </div>
      <div className="about-texts">
        <h1>About Me</h1>
        <p>
          Passionate and ambitious developer with expertise in frontend
          (React.js) and backend (Node.js) development. Eager learner, driven by
          innovation and continuous growth. Seeking collaborations with
          experienced professionals on meaningful projects. Let's connect and
          create remarkable experiences in technology!
        </p>
        <div className="overview">
          <Info
            selectTab={() => handleTabChange("Skills")}
            active={tab === "Skills"}
          >
            Skills
          </Info>
          <Info
            selectTab={() => handleTabChange("Education")}
            active={tab === "Education"}
          >
            Education
          </Info>
          <Info
            selectTab={() => handleTabChange("Experience")}
            active={tab === "Experience"}
          >
            Experience
          </Info>
        </div>
        <div className="overview-info-list">
          {TAB_DATA.find((t) => t.id === tab).content}
        </div>
      </div>
    </div>
  );
};

export default About;
