import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      copyright &copy; {new Date().getFullYear()} <a href="/">Saroj Subedi</a>
    </div>
  );
};

export default Footer;
