import React from "react";
import "../styles/Home.css";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsTelegram } from "react-icons/bs";
import saroj from "../assets/ss.png";
import CV from "../assets/CV_SarojSubedi.pdf";

const Home = () => {
  return (
    <div className="home-container" id="home">
      <div className="midsec">
        <div>
          <p className="hello">Hello there!</p>
          <h1 className="intro">
            I am <span className="name">Saroj Subedi</span>
          </h1>
          <p className="description">Full Stack Web Developer</p>
          <a href={CV} className="download" download="Saroj_CV">
            Dowload CV
          </a>
          <div className="socials">
            <i>
              <a href="https://github.com/sarojsamarpan" target="top">
                <BsGithub />
              </a>
            </i>
            <i>
              <a href="https://www.linkedin.com/in/callmesaroj/" target="top">
                <BsLinkedin />
              </a>
            </i>
            <i>
              <a href="https://t.me/sarojsubedi" target="top">
                <BsTelegram />
              </a>
            </i>
            <i>
              <a href="https://facebook.com/feeltheworldtechy" target="top">
                <BsFacebook />
              </a>
            </i>
          </div>
        </div>
        <div className="profile">
          <img
            className="profile-image"
            src={saroj}
            alt="saroj-desc-profile"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Home;
