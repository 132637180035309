import React, { useState } from "react";
import "../styles/Header.css";
import { FaBars } from "react-icons/fa";
import { BsSun } from "react-icons/bs";
import { BsMoon } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dark, setDark] = useState(false);

  if (!localStorage.getItem("Theme")) {
    localStorage.setItem("Theme", "Light");
  }

  const darkTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
  };

  const lightTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
  };

  const changeTheme = () => {
    if (localStorage.getItem("Theme") === "Light") {
      localStorage.setItem("Theme", "Dark");
      setDark(true);
    } else {
      localStorage.setItem("Theme", "Light");
      setDark(false);
    }
  };

  if (localStorage.getItem("Theme") === "Dark") {
    darkTheme();
  } else {
    lightTheme();
  }

  return (
    <header className="header">
      <div className="logo">
        <a href="/">SAROJ</a>
      </div>
      <nav className="nav">
        <i className="menu-bar" onClick={() => setMenuOpen(!menuOpen)}>
          {!menuOpen ? <FaBars /> : <RxCross1 />}
        </i>
        <ul
          className={!menuOpen ? "nav-items" : "nav-items menuOpen"}
          onClick={() => setMenuOpen(true)}
        >
          <li>
            <a href="#home">HOME</a>
          </li>
          <li>
            <a href="#about">ABOUT ME</a>
          </li>
          <li>
            <a href="#projects">PROJECTS</a>
          </li>
          <li>
            <a href="#contact">CONTACT</a>
          </li>
          <li
            className="theme-toggle"
            onClick={() => {
              changeTheme();
            }}
          >
            {dark || localStorage.getItem("Theme") === "Dark" ? (
              <BsMoon />
            ) : (
              <BsSun />
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
