import React from "react";
import "../styles/Contact.css";
import Footer from "./Footer";

const Contact = () => {
  return (
    <div className="contact-container" id="contact">
      <h1>Contact</h1>
      <div class="contact-content">
        <div class="right-side">
          <div class="topic-text">Send me a message</div>
          <p>
            Ready to elevate your digital experience? Whether you have a project
            in mind, need technical advice, or just want to connect, I'm here to
            help. Drop me a message below, and let's turn your ideas into
            reality!
          </p>
          <form action="#">
            <div class="input-box">
              <input type="text" placeholder="Enter your name" />
            </div>
            <div class="input-box">
              <input type="text" placeholder="Enter your email" />
            </div>
            <div class="input-box message-box">
              <textarea></textarea>
            </div>
            <div class="button">
              <input type="button" value="Send Now" />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
