import React from "react";
import "../styles/Projects.css";

const Projects = () => {
  return (
    <div className="projects-container" id="projects">
      <h1>Projects</h1>
      <div className="projects-contents">
        <div className="project-box">
          <div className="p-card">
            <div className="project-image">
              <img
                src="https://repository-images.githubusercontent.com/469053200/cdd67271-e4f9-4721-bfcf-55dc42ee36e0"
                alt="project one"
              ></img>
            </div>
            <div className="contents">
              <div className="project-title">
                <p>Project One</p>
              </div>
              <div className="project-action">
                <a href="/">GitHub</a>
                <a href="/">Demo</a>
              </div>
            </div>
          </div>
        </div>
        <div className="project-box">
          <div className="p-card">
            <div className="project-image">
              <img
                src="https://repository-images.githubusercontent.com/469053200/cdd67271-e4f9-4721-bfcf-55dc42ee36e0"
                alt="project one"
              ></img>
            </div>
            <div className="contents">
              <div className="project-title">
                <p>Project Two</p>
              </div>
              <div className="project-action">
                <a href="/">GitHub</a>
                <a href="/">Demo</a>
              </div>
            </div>
          </div>
        </div>
        <div className="project-box">
          <div className="p-card">
            <div className="project-image">
              <img
                src="https://repository-images.githubusercontent.com/469053200/cdd67271-e4f9-4721-bfcf-55dc42ee36e0"
                alt="project one"
              ></img>
            </div>
            <div className="contents">
              <div className="project-title">
                <p>Project Three</p>
              </div>
              <div className="project-action">
                <a href="/">GitHub</a>
                <a href="/">Demo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
