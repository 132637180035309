import React from "react";

const Info = ({ active, selectTab, children }) => {
  const buttonClasses = active
    ? "overview-btn-active"
    : "overview-btn-inactive";
  return (
    <p onClick={selectTab} className={`overview-btn ${buttonClasses}`}>
      {children}
    </p>
  );
};

export default Info;
